// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H2 from "../../styleguide/components/Heading/H2.res.js";
import * as H4 from "../../styleguide/components/Heading/H4.res.js";
import * as Button from "../../styleguide/components/Button/Button.res.js";
import * as Container from "../../styleguide/components/Container/Container.res.js";
import * as SuccessImage from "../product-reservation/confirm/SuccessImage.res.js";
import * as Globals from "Common/globals";
import * as JsxRuntime from "react/jsx-runtime";
import * as ThanksForContactScss from "./ThanksForContact.scss";

var css = ThanksForContactScss;

function ThanksForContact$default(props) {
  return JsxRuntime.jsx(Container.make, {
              className: Cx.cx([
                    css.layout,
                    css.additionalPadding
                  ]),
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(SuccessImage.make, {}),
                      JsxRuntime.jsx(H2.make, {
                            className: css.formTitle,
                            children: "Thank you for contacting us!"
                          }),
                      JsxRuntime.jsx("p", {
                            children: "One of our specialists will be in contact with you shortly to see if you have questions or need assistance with an upcoming project."
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(H4.make, {
                                    className: css.buttonContainerHeading,
                                    children: "Want to speak with an expert now?"
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs(Button.AsLink.make, {
                                          href: Globals.DCContactNumberHRef,
                                          size: "MD",
                                          color: "Primary",
                                          expanded: false,
                                          className: css.button,
                                          children: [
                                            JsxRuntime.jsx("i", {
                                                  className: "fa fa-phone-volume"
                                                }),
                                            Globals.DCContactNumber
                                          ]
                                        }),
                                    className: css.buttonContainer
                                  })
                            ]
                          })
                    ],
                    className: css.formContainer
                  })
            });
}

var $$default = ThanksForContact$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
